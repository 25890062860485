@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&family=Zen+Maru+Gothic:wght@500;900&display=swap');
html {
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #eeeffa;
  padding: 60px 0;
  //background: #AAC963;
  //font-family: 'Roboto', sans-serif;
  //background: #42c694;
}
* {
  margin: 0;
  padding: 0;
  //font-family: 'Zen Maru Gothic', sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
.index-max_value_section {
  position: relative;
  height: 85vw;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 0 0 30px 0;
  color: #333;
  &--title {
    font-size: 18px;
    opacity: 0.8;
    font-family: 'Zen Maru Gothic', sans-serif;
  }
  &--value {
    font-size: 65px;
    font-weight: bold;
    font-family: 'Zen Maru Gothic', sans-serif;
    margin: 0 0 6px 0;
  }
  &--type {
    font-size: 40px;
    margin: 0 6px 0 0;
  }
  &--note {
    font-size: 14px;
    opacity: 0.6;
    font-family: 'Zen Maru Gothic', sans-serif;
  }
  &--inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background: #fff;
    width: 78vw;
    height: 78vw;
    border-radius: 50%;
  }
}
.index-pie_chart {
  width: 85vw;
  margin: 0 auto;
  position: absolute;
  left: calc(50% - 42.5vw);
  top: 0;
  pointer-events: none;
  &:before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    width: calc(85vw - 1px);
    height: calc(85vw - 1px);
    left: 0;
    top: 0;
    box-shadow: -9px -7px 8px rgb(255 255 255), 0 3px 14px rgb(0 0 0 / 40%);
    pointer-events: none;
  }
}
.index-table_data {
  .ka-table-wrapper {
    max-height: 600px;
  }
  .ka-cell-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .delete-row-column-button {
    background: red;
    width: 10px;
    height: 10px;
  }
}
.index-monthly {
  width: 100%;
  margin: 0 0 12px 0;
  .highcharts-legend, .highcharts-credits {
    display: none;
  }
  .highcharts-background {
    fill: transparent;
  }
}
.index-stocks {
  //margin: -28px 0 0 0;
  //position: relative;
  //padding: 30px 0px 30px 24px;
  padding: 24px;
  font-family: 'Zen Maru Gothic', sans-serif;
  &--list {
    list-style: none;
  }
  &--item {
    background: #fff;
    border-radius: 24px 0 0 24px;
    padding: 15px;
    box-shadow: -9px -7px 11px rgb(255 255 255 / 100%), 0 3px 14px rgb(0 0 0 / 27%);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    margin: 0 0 15px 0;
    overflow: hidden;
    &:last-child {
      border-bottom: 0;
    }
  }
  &--item_box {
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(169, 133, 124, 0) 49%, rgba(232, 155, 16, 1) 49%, rgba(245, 80, 71, 1) 100%);
    position: relative;
  }
  &--color {
    cursor: pointer;
    width: 15px;
    height: 15px;
    box-shadow: -9px -7px 11px rgb(255 255 255 / 100%), 0 3px 14px rgb(0 0 0 / 27%);
    display: block;
    border-radius: 50%;
    margin: 0 9px 0 0;
    position: relative;
    top: 3px;
  }
  &--ticker {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  &--dividend {
  }
  &--dividend_price {
    font-size: 20px;
    font-weight: bold;
  }
  &--dividend_per {
    font-size: 12px;
    font-weight: bold;
    margin: 0 6px 3px 0;
    background: #a29d28;
    border-radius: 20px;
    color: #fff;
    padding: 0px 6px 2px 6px;
    position: relative;
    bottom: 2px;
  }
  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &--company_name {
    color: #5b7a7a;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  &--delete {
    width: 50px;
    height: 50px;
    svg {
    }
    &.is_opened {
      color: #333;
    }
  }
  &--stock_price {
    margin: 0 12px 0 0;
  }
  &--stock_dividend {
  }
  &--stock_monthly {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  &--input {
    width: 100%;
    box-sizing: border-box;
    height: 45px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
    margin: 12px 0 0 0;
  }
}
.index-right_bottom_button {
  position: fixed;
  right: 15px;
  bottom: 15px;
}
.index-suggest_button {
  position: fixed;
  font-family: 'Zen Maru Gothic', sans-serif;
  transition: 0.35s cubic-bezier(0.16, 1, 0.3, 1);
  transform: translateY(100vh);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #eeeffa;
  padding: 30px;
  box-sizing: border-box;
  &--input {
    width: 100%;
    margin: 0 0 30px 0;
    display: block;
    input {
      border: 0;
      width: 100%;
      box-sizing: border-box;
      background: transparent;
      height: 50px;
      box-shadow: -2px -1px 4px rgb(255 255 255), 0 3px 6px rgb(0 0 0 / 27%);
      border-radius: 30px;
    }
  }
  .react-autosuggest__suggestions-container--open {
    width: 100%;
    border: 0;
    margin: 6px 0 0 0;
    border-radius: 20px;
  }
  &--sub_title {
    font-size: 14px;
  }
  &.is_show {
    transform: translateY(0);
    .index-suggest_button {
      &--background {
      }
    }
  }
  //display: none;
  //position: fixed;
  //right: 15px;
  //bottom: 80px;
  //font-family: 'Zen Maru Gothic', sans-serif;
  //z-index: 100;
  //&--add {
  //  width: 240px;
  //  margin: 12px 0 0 0;
  //  height: 30px;
  //  border-radius: 4px;
  //  padding: 10px 20px;
  //  font-size: 16px;
  //  border: 2px solid #000;
  //  display: block;
  //}
  //&--submit {
  //  margin: 12px 0 0 0;
  //  width: 100%;
  //  border: 0;
  //  padding: 12px 0;
  //  background: rgb(89, 174, 164);
  //  font-weight: bold;
  //  color: #fff;
  //}
}
.monthly_chart {
  display: flex;
  justify-content: space-between;
  padding: 40px 15px 30px 15px;
  align-items: center;
  overflow: hidden;
  &--box {
    $colors: '#4D908E', '#43AA8B', '#90BE6D', '#F9C74F', '#F9844A', '#F8961E', '#F3722C', '#F94144', '#E9D8A6', '#94D2BD', '#0A9396', '#005F73';
    position: relative;
    &:nth-child(2n+1) {
      .monthly_chart--price_label {
        top: 0;
        &:before {
          bottom: -33px;
          height: 32px;
        }
        &:after {
          bottom: -34px;
        }
      }
    }
    &:nth-child(2n) {
      .monthly_chart--price_label {
        top: 15px;
      }
    }
    @each $color in $colors {
      $i: index($colors, $color);
      &:nth-child(#{$i}) {
        .monthly_chart--child_bar {
          transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
          display: block;
          background: #{$color};
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
        }
      }
    }
  }
  &--bar {
    height: 100px;
    width: 23px;
    border-radius: 30px;
    box-shadow: -2px -1px 4px rgb(255 255 255), 0 3px 6px rgb(0 0 0 / 27%);
    overflow: hidden;
    position: relative;
    //background: #fff;
  }
  &--child_bar {
    height: 0px;
  }
  &--month_label {
    font-size: 10px;
    font-family: "Zen Maru Gothic", sans-serif;
    text-align: center;
    font-weight: bold;
    position: absolute;
    bottom: -18px;
    width: 23px;
  }
  &--price_label {
    font-size: 10px;
    font-family: "Zen Maru Gothic", sans-serif;
    font-weight: bold;
    position: absolute;
    margin: -38px 0 0 0;
    width: 60px;
    left: -19px;
    text-align: center;
    z-index: 10;
    &:before {
      content: '';
      width: 1px;
      height: 20px;
      background: #333;
      opacity: 0.7;
      position: absolute;
      left: 50%;
      bottom: -19px;
    }
    &:after {
      content: '';
      width: 5px;
      height: 5px;
      background: #333;
      border-radius: 10px;
      display: block;
      position: absolute;
      bottom: -20px;
      left: calc(50% - 2px);
    }
  }
}
.index-title {
  font-size: 20px;
  margin: 0 0 24px 0;
  color: #5e8e44;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &--note {
    font-size: 14px;
  }
}
.index-sub_title {
  font-size: 18px;
  margin: 0 0 18px 0;
  font-weight: bold;
  color: #5e8e44;
  display: flex;
  font-family: 'Zen Maru Gothic', sans-serif;
  justify-content: space-between;
  align-items: center;
}
.index-stock_list {
  list-style: none;
  &--item {
    background: transparent;
    border-radius: 30px;
    box-shadow: -5px -4px 3px rgb(255 255 255 / 40%), 4px 3px 8px rgb(0 0 0 / 27%);
    padding: 6px 0;
    position: relative;
    overflow: hidden;
    margin: 0 0 21px 0;
    max-height: 50px;
    transition: max-height 0.6s, background 0.3s;
    &.is_show {
      max-height: 100vh;
      background: #fff;
    }
  }
  &--header {
    position: relative;
    margin: 0 0 0 60px;
  }
  &--color {
    background: green;
    width: 25px;
    height: 25px;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 16px;
    left: 18px;
    &:after {
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      left: -8px;
      top: -8px;
      z-index: -1;
      box-shadow: -4px -3px 6px rgb(255 255 255), 2px 3px 4px rgb(0 0 0 / 18%);
    }
  }
  &--ticker {
    width: 100px;
    font-weight: bold;
    display: block;
  }
  &--current_price {
    text-align: right;
    position: absolute;
    right: 28px;
    top: 3px;
    font-weight: bold;
  }
  &--company_name {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding: 0 24px 0 0;
    color: #333;
    font-size: 12px;
    box-sizing: border-box;
  }
  &--body {
    padding: 12px 28px 12px 60px;
  }
  &--price {
    font-weight: bold;
    font-size: 16px;
  }
  &--prices {
    display: flex;
    justify-content: space-between;
  }
  &--stock_price {
    font-size: 12px;
  }
  &--monthly {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    font-size: 12px;
  }
  &--controller {
    padding: 0 0 0 60px;
    display: flex;
    align-items: center;
  }
  &--stock_number {
    font-size: 20px;
    font-weight: bold;
    margin: 0 12px;
    position: relative;
    top: -2px;
    background: #fff;
    padding: 3px 0;
    border-radius: 12px;
    width: 40px;
    text-align: center;
  }
  &--controller_button {
    color: #5e8e44;
  }
  &--remove_button {
    background: #f3c439;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    //width: 120px;
    border: 0;
    width: 0;
  }
}
.picker_section {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: none;
  &.is_showed {
    display: block;
    .picker_section {
      &--background {
        display: block;
      }
      &--color_picker {
        display: block;
      }
    }
  }
  &--background {
    background: rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: none;
  }
  &--color_picker {
    display: none;
    position: absolute;
    left: calc(50% - 112px);
    top: calc(50% - 112px);
  }
}
.index-main_section {
  transition: 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  &.is_backed {
    transform: perspective(75em) rotateX(18deg);
  }
}
#root {
  overflow: hidden;
}
path {
  pointer-events: none;
}
